/* -------------------------------------------------------------
//  Button
// -----------------------------------------------------------*/
.button {
  align-items: center;
  background-color: $button-background-color;
  border: 1px solid $button-border-color;
  border-radius: 3px;
  color: $button-font-color;
  cursor: pointer;
  display: inline-flex;
  font-size: to-rem($button-font-size);
  font-weight: 700;
  line-height: to-rem($button-line-height);
  margin-bottom: to-rem($button-margin-bottom);
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  // Flex Hack
  & > * {
    //margin-right: 0.5ch;
  }

  & > i {
    font-size: to-rem($button-font-size);
  }

  &:hover {
    background-color: $button-background-color-hover;
    border: 1px solid $button-border-color-hover;
    color: $button-font-color-hover;
  }

  &--primary {
    background-color: $button-primary-background-color;
    border: 1px solid $button-primary-border-color;
    color: $button-primary-font-color !important;

    &:hover {
      background-color: $button-primary-background-color-hover;
      border: 1px solid $button-primary-border-color-hover;
    }
  }

  &--link {
    background-color: $button-link-background-color;
    border: $button-link-border-color;
    color: $button-link-font-color;

    &:hover {
      background-color: $button-link-background-color-hover;
      border: $button-link-border-color-hover !important;
      color: $button-link-font-color-hover;
    }
  }
}
