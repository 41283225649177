/* -------------------------------------------------------------
//  Navigation
// -----------------------------------------------------------*/

.nav {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: to-rem(30) 0 to-rem(30);

  @media (min-width: 1200px) {
    padding: to-rem(60) 0 to-rem(60);
  }

  &__container {
    // Mobile First Dropdown Navigation
    background-color: $nav-container-background-color;

    visibility: hidden;
    opacity: 0;

    backdrop-filter: blur(1rem);

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    inset: 0 0 0 0;
    position: fixed;

    transition: all 500ms ease-in-out;

    // Desktop Viewport
    
    /* @media (min-width: 1200px) {
      visibility: visible;
      opacity: 1;      

      position: relative;
      top: auto !important;
      
      display: flex;
      flex-direction: row;

      padding: 0px 15px 15px 0px;
    } */
    
    //nav__container toggle
    &.is-open {
      visibility: visible;
      opacity: 1;
    }
  }

  // nav__list ul
  &__list {
    position: relative;

    // Desktop Viewport
    @media (min-width: 1200px) {
      
    }

    &.active {
      
    }
  }
  // nav__list li
  &__item {

    // Desktop Viewport
    @media only screen and (min-width: 1200px) {
      //display: inline;
    }
  }
  // nav__list li a
  &__link {
    color: $nav-list-font-color !important;
    font-family: $nav-list-font-family;
    font-size: to-rem($nav-list-font-size);
    font-weight: $nav-list-font-weight;
    line-height: to-rem($nav-list-line-height);

    display: block;
    text-decoration: none;
    margin: 0;
    padding: 15px;
    position: relative;

    // Desktop Viewport
    /* @media only screen and (min-width: 1200px) {
      display: inline-block;
      padding: 0;
      margin: 0 7px 0 10px;
    } */

    &:hover, &:focus {

      // Desktop Viewport
      /* @media only screen and (min-width: 1200px) {
        border-bottom: 1px solid $color-primary;
        color: $color-black-90 !important;
      } */
      color: $color-black-40 !important;
    }

    &.active {

      // Desktop Viewport
      /* @media only screen and (min-width: 1200px) {
        // Desktop Viewport
        border-bottom: 1px solid $color-primary;
        color: $color-black-90 !important;
      }
      //color: $color-primary !important; */
    }
  }
}


// Burger Button
.nav__toggle-button {
  cursor: pointer;
  height: 20px;
  position: relative;
  width: 20px;
  z-index: 15;
  transition: top $transition--slow;
}

/* Burgerbutton */
.line {
  background-color: $color-black-01;
  display: block;
  height: 1px;
  position: absolute;
  width: 20px;
}

.line--top {
  left: 0;
  top: 4px;
}
.line--middle {
  left: 0;
  top: 11px;
}
.line--bottom {
  left: 0px;
  top: 18px;
}

.nav__toggle-button.toggle .line--top {
  animation: line-top-animation 400ms 0s 1 forwards;
}

@keyframes line-top-animation {
  from {
    top: 4px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(45deg);
  }
}

.nav__toggle-button.toggle .line--bottom {
  animation: line-bottom-animation 400ms 0s 1 forwards;
}


@keyframes line-bottom-animation {
  from {
    top: 18px;
    transform: rotate(0deg);
  }
  to {
    top: 11px;
    transform: rotate(-45deg);
  }
}

.nav__toggle-button.toggle .line--middle {
  animation: line-middle-animation 400ms 0s 1 forwards;
}
@-webkit-keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

@keyframes line-middle-animation {
  from {
    width: 22px;
  }
  to {
    width: 0px;
  }
}

.nav__toggle-button .line--top {
  animation: line-top-animation-back 400ms 0s 1 forwards;
}

@keyframes line-top-animation-back {
  from {
    top: 11px;
    transform: rotate(45deg);
  }
  to {
    top: 4px;
    transform: rotate(0deg);
  }
}
.nav__toggle-button .line--bottom {
  animation: line-bottom-animation-back 400ms 0s 1 forwards;
}

@keyframes line-bottom-animation-back {
  from {
    top: 11px;
    transform: rotate(-45deg);
  }
  to {
    top: 18px;
    transform: rotate(0deg);
  }
}
.nav__toggle-button .line--middle {
  animation: line-center-animation-back 400ms 0s 1 forwards;
}

@keyframes line-middle-animation-back {
  from {
    width: 0px;
  }
  to {
    width: 22px;
  }
}

