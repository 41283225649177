/* -------------------------------------------------------------
//  Section
// -----------------------------------------------------------*/
.section {
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;

  &::before,
  &::after {
    content: '';
    display: block;
  }

  &::before {
    margin-bottom: to-rem($section-margin);
  }

  &::after {
    margin-top: to-rem($section-margin);
  }

  &--light {
    background-color: $section-background-light;
  }
  &--dark {
    background-color: $section-background-dark;
  }
}