@mixin focus-ring {
  outline: 3px solid rgba($color-primary, 0.5);
}

.textfield {
  margin-bottom: to-rem($textfield-margin-bottom);

  &__label {
    display: block;
    font-family: $textfield-label-font-family, Arial, sans-serif;
    font-size: to-rem($textfield-label-font-size);
    margin-bottom: 0.25rem;
    pointer-events: none;
    //
  }

  &__input {
    background-color: $textfield-input-background-color;
    border: 1px solid $textfield-input-border-color;
    border-radius: 3px;
    color: $color-black-90;
    display: block;
    font-family: $textfield-input-font-size, Arial, sans-serif;
    padding-left: to-rem($textfield-input-font-size);
    height: 48px;
    width: 100%;
    //
    &:focus {
      @include focus-ring;
    }
  }
  // Label after input
  &__input:not(:placeholder-shown) + #{&}__label {
    //
  }
  
}

.textarea {
  margin-bottom: to-rem($font-medium-line-height);

  &__label {
    display: block;
    font-family: $textfield-label-font-family, Arial, sans-serif;
    font-size: to-rem($textfield-label-font-size);
    margin-bottom: 0.25rem;
    pointer-events: none;
    //
  }

  &__input {
    background-color: $textfield-input-background-color;
    border: 1px solid $textfield-input-border-color;
    border-radius: 3px;
    color: $color-black-90;
    display: block;
    font-family: $textfield-input-font-family, Arial, sans-serif;
    padding-top: 0.75rem;
    padding-left: to-rem($textfield-input-font-size);
    height: 48px;
    width: 100%;   
    //
    &:focus {
      @include focus-ring;
    }
  }
}



