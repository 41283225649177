/* -------------------------------------------------------------
//  Basics
// ---------------------------------------------------------- */
:root {
  font-size: $root-font-size + px;
}

body {
  background-color: $body-background;
  color: $body-text;
  font-family: $body-font-family, Helvetica, Arial;
  font-size: to-rem($body-font-size);
  line-height: to-rem($body-line-height);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}
