p {
  font-size: to-rem($font-medium-font-size);
  line-height: to-rem($font-medium-line-height);
  margin-bottom: to-rem($font-medium-line-height);
}

p.lead {
  font-size: to-rem($font-xlarge-font-size);
  line-height: to-rem($font-xlarge-line-height);
  margin-bottom: to-rem($font-xlarge-line-height);
}

p.small,
small {
  font-size: to-rem($font-small-font-size);
  line-height: to-rem($font-small-line-height);
  margin-bottom: to-rem($font-small-line-height);
}

strong {
  font-weight: 700;
}

.col-12 > p {
  margin-top: 0; // resets margin of first paragraph
}
