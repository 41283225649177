footer {
  background-color: $footer-background-color;
  color: $footer-text-color;
  padding: to-rem(60) 0 to-rem(60);
}

/* Footernavigation */
.footer {

  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
  
  &__list {
    display: flex;

    span {
      margin-left: $font-medium-font-size + px;
      margin-right: $font-medium-font-size + px;
    }

  }

  &__item {
    border: 0 none;
    text-decoration: none !important;

    &:last-of-type {
      margin-right: 0;
    }
    
  }
  &__link {
    // color: $color-black-90 !important;

    &:hover {
      
    }
  }
}

.social-icons {
  // color: $color-black-90 !important;
  font-size: 0;
  line-height: 0;
  text-decoration: none !important;
  
  &__item {
    display: inline-block;
    @media only screen and (min-width: 768px) {
        margin-left: -9px;
    }
  }
  &__link{

  }
}