/* -------------------------------------------------------------
//  Images
// -----------------------------------------------------------*/
img {
  height: auto;
  margin-bottom: to-rem($font-medium-line-height);
  width: 100%;
}
.image_container {
  margin: 0;
}
