$glide-class: 'glide' !default;
$glide-element-separator: '__' !default;
$glide-modifier-separator: '--' !default;

.#{$glide-class} {
  $this: &;

  $se: $glide-element-separator;
  $sm: $glide-modifier-separator;

  &#{$se}arrow {
    position: absolute;
    display: block;
    top: 50%;
    z-index: 2;
    color: white;
    text-transform: uppercase;
    padding: 9px 12px;
    background-color: transparent;
    border: 2px solid rgba(255, 255, 255, 0.5);
    border-radius: 4px;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
    opacity: 1;
    cursor: pointer;
    transition: opacity 150ms ease, border 300ms ease-in-out;
    transform: translateY(-50%);
    line-height: 1;

    &:focus { outline: none; }
    &:hover { border-color: white; }

    &#{$sm}left {
      left: 2em;
    }

    &#{$sm}right {
      right: 2em;
    }

    &#{$sm}disabled {
      opacity: 0.33;
    }
  }

  &#{$se}bullets {
    position: absolute;
    z-index: 2;
    bottom: 2em;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
  }

  &#{$se}bullet {
    background-color: rgba(255, 255, 255, 0.5);
    width: 9px;
    height: 9px;
    padding: 0;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: all 300ms ease-in-out;
    cursor: pointer;
    line-height: 0;
    box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
    margin: 0 0.25em;

    &:focus {
      outline: none;
    }

    &:hover,
    &:focus {
      border: 2px solid white;
      background-color: rgba(255, 255, 255, 0.5);
    }

    &#{$sm}active {
      background-color: white;
    }
  }

  &#{$sm}swipeable {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }

  &#{$sm}dragging {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}


/* -------------------------------------------------------------
//  Slider
// -----------------------------------------------------------*/
// .glide__elment {
//   background-color: $color-black-05;
//   display: grid;
//   text-align: center;
//   min-height: 350px;
//   align-items: center;
// }

// .icon-arrow {
//   .icon-chevron-left {
//     &::before{
//       color: $slider-icon-color;
//     }
//   }
//   .icon-chevron-right {
//     &::before{
//       color: $slider-icon-color;
//     }
//   }
// }

// .glide__bullet--active {
//   background-color: $slider-bullet-active;
// }

// .js-slider {
//   .glide__elment {
//     display: grid;
//     text-align: center;
//     min-height: 350px;
//     align-items: center;
//   }
//   .icon-arrow {
//     .icon-chevron-left {
//       &::before{
//         color: $slider-icon-color;
//       }
//     }
//     .icon-chevron-right {
//       &::before{
//         color: $slider-icon-color;
//       }
//     }
//   }

//   .glide__bullet--active {
//     background-color: $slider-bullet-active;
//   }
// }

// /* -------------------------------------------------------------
// //  Slider cards
// // -----------------------------------------------------------*/
// #slider__kacheln, #slider__kacheln--2, .js-slider {
//   .slider__arrow {
//     position: absolute;
//     top: 50%;
//     z-index: 1;
//     width: 24px;
//     height: 24px;
//     margin-top: -9px;
//     border-radius: 50%;
//     background-color: #818999;
//     -webkit-transition: all .2s ease-in-out;
//     transition: all .2s ease-in-out;
//     -webkit-box-shadow: 0 0.5rem 4rem 0 rgb(0 0 0 / 50%);
//     box-shadow: 0 0.5rem 4rem 0 rgb(0 0 0 / 50%);
//   }
//   .slider__arrow--prev {
//     left: 1.5rem;
//   }
//   .slider__arrow--next {
//     right: 1.5rem;
//   }
//   .glide {
//     cursor: -webkit-grab;
//   }
//   .slider__frame {
//     border-radius: 0.1666666667em;
//     text-align: center;
//     height: 350px;
//     line-height: 150px;
//     -webkit-box-shadow: inset 0 0 0 2px rgba(27, 26, 26, 0.5);
//     box-shadow: inset 0 0 0 2px rgba(29, 28, 28, 0.5);
//     color: $slider-frame-color;
//     background: transparent;
//     font-size: 1em;
//     font-weight: 900;
//     width:265px;
//     margin-left: 5px;
//     margin-right: 5px;
//   }
//   .slider__frame > * {
//     display: grid;
//     grid-template-columns: 1fr;
//   }
//   .slider__frame[class*=active] {
//     -webkit-box-shadow: none;
//     box-shadow: none;
//     background: $slider-frame-background-active;
//   }
//   .frame__image {
//     height: 158px;
//     background: #818999;
//   }
// }


.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide * {
  box-sizing: inherit;
}
.glide__track {
  overflow: hidden;
}
.glide__slides {
  position: relative;
  width: 100%;
  list-style: none;
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  overflow: hidden;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  will-change: transform;
}
.glide__slides--dragging {
  user-select: none;
}
.glide__slide {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  white-space: normal;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  background-color: $slider-background-color;
  min-height: 250px; /* demo value */
}
.glide__slide a {
  user-select: none;
  -webkit-user-drag: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.glide__arrows {
  -webkit-touch-callout: none;
  user-select: none;
}
.glide__arrow {
  border: 1px solid #000;
  margin-top: -10px;
  position: absolute;
  top: 50%;
}
.glide__arrow--left {
  left: 10px;
}
.glide__arrow--right {
  right: 10px;
}
.glide__bullets {
  -webkit-touch-callout: none;
  user-select: none;
  text-align: center;
}
.glide__bullet {
  background-color: $slider-bullet;
  border-radius: 50%;
  height: 12px;
  width: 12px;
}
.glide__bullet--active {
  background-color: $slider-bullet-active;
}
.glide--rtl {
  direction: rtl;
}
