// SETTINGS
@import 'settings/tokens_default';
@import 'settings/tokens';
@import 'settings/config';

@import 'settings/animations';
@import 'settings/elevations';
@import 'settings/fonts';
@import 'settings/iconfont';
@import 'settings/spacings';
@import 'settings/states';

// TOOLS
@import 'tools/clamp-element';
@import 'tools/to-rem';

// GENERIC
@tailwind base;
//@import 'generic/reset';

// ELEMENTS
@import 'elements/body';
@import 'elements/headings';
@import 'elements/images';
@import 'elements/links';
@import 'elements/lists';
@import 'elements/logo';
@import 'elements/paragraph';
@import 'elements/section';

// OBJECTS
@import 'vendor/bootstrap-grid.min';
@import 'vendor/glide.core';

// COMPONENTS
@tailwind components;
@import 'components/header';
@import 'components/accordion';
@import 'components/buttons';
@import 'components/checkbox';
@import 'components/contact-form';
@import 'components/cookie-banner';
@import 'components/footer';
@import 'components/header';
@import 'components/navigation';
@import 'components/radio';
@import 'components/carousel';

// UTILITIES
@tailwind utilities;
@import 'utilities/helper';
@import 'utilities/colors';