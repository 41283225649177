/* -------------------------------------------------------------
//  Links
// -----------------------------------------------------------*/
.link {
  text-decoration: none;
  outline: none;
  position: relative;
}

// Quirky Line Animation
.link__graphic {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  fill: none;
  stroke: #fff;
  stroke-width: 1px;
}

.link__graphic--slide {
  top: -3px;
  stroke-width: 2px;
  transition: transform 0.7s;
  transition-timing-function: cubic-bezier(0, 0.25, 0.5, 1);
}

.link:hover .link__graphic--slide {
  transform: translate3d(-66.6%, 0, 0);
}

.link__graphic--stroke path {
  stroke-dasharray: 1;
  stroke-dashoffset: 1;
}

.link:hover .link__graphic--stroke path {
  stroke-dashoffset: 0;
}

/* Trick from https://css-tricks.com/a-trick-that-makes-drawing-svg-lines-way-easier/ */


.link__graphic--arc {
top: 73%;
left: -23%;
}

.link__graphic--arc path {
  transition: stroke-dashoffset 0.4s cubic-bezier(0.7, 0, 0.3, 1);
}

.link:hover .link__graphic--arc path {
  transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
  transition-duration: 0.3s;
}

.link__graphic--scribble {
  top: 100%;
}

.link__graphic--scribble path {
  transition: stroke-dashoffset 0.6s cubic-bezier(0.7, 0, 0.3, 1);
}

.link:hover .link__graphic--scribble path {
  transition-timing-function: cubic-bezier(0.8, 1, 0.7, 1);
  transition-duration: 0.3s;
}